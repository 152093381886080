/* eslint-disable react/jsx-props-no-spreading */
import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';

import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

import { useStore } from '../../../../../../context';

const Image = () => {
  const data = useStaticQuery(graphql`
    fragment cloudBig on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [1600]
          formats: [AUTO, WEBP, AVIF]
          quality: 80
        )
      }
    }
    fragment cloudSmall on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [800]
          formats: [AUTO, WEBP, AVIF]
          quality: 80
        )
      }
    }
    query {
      lightCloud1: file(relativePath: { eq: "home/light-cloud1.png" }) {
        ...cloudBig
      }
      lightCloud1Mobile: file(relativePath: { eq: "home/light-cloud1-sm.png" }) {
        ...cloudSmall
      }
      lightCloud2: file(relativePath: { eq: "home/light-cloud2.png" }) {
        ...cloudBig
      }
      lightCloud2Mobile: file(relativePath: { eq: "home/light-cloud2-sm.png" }) {
        ...cloudSmall
      }
      lightCloud3: file(relativePath: { eq: "home/light-cloud3.png" }) {
        ...cloudBig
      }
      lightCloud3Mobile: file(relativePath: { eq: "home/light-cloud3-sm.png" }) {
        ...cloudSmall
      }
      lightCloud4: file(relativePath: { eq: "home/light-cloud4.png" }) {
        ...cloudBig
      }
      lightCloud4Mobile: file(relativePath: { eq: "home/light-cloud4-sm.png" }) {
        ...cloudSmall
      }
      darkCloud1: file(relativePath: { eq: "home/dark-cloud1.png" }) {
        ...cloudBig
      }
      darkCloud1Mobile: file(relativePath: { eq: "home/dark-cloud1-sm.png" }) {
        ...cloudSmall
      }
      darkCloud2: file(relativePath: { eq: "home/dark-cloud2.png" }) {
        ...cloudBig
      }
      darkCloud2Mobile: file(relativePath: { eq: "home/dark-cloud2-sm.png" }) {
        ...cloudSmall
      }
      darkCloud3: file(relativePath: { eq: "home/dark-cloud3.png" }) {
        ...cloudBig
      }
      darkCloud3Mobile: file(relativePath: { eq: "home/dark-cloud3-sm.png" }) {
        ...cloudSmall
      }
      darkCloud4: file(relativePath: { eq: "home/dark-cloud4.png" }) {
        ...cloudBig
      }
      darkCloud4Mobile: file(relativePath: { eq: "home/dark-cloud4-sm.png" }) {
        ...cloudSmall
      }
      dots: file(relativePath: { eq: "home/dots.png" }) {
        ...cloudBig
      }
      dotsMobile: file(relativePath: { eq: "home/dots-sm.png" }) {
        ...cloudSmall
      }
    }
  `);

  // Get the scrollPos from the store.
  const [{ scrollPos }] = useStore();
  const { y, height } = scrollPos;

  const layers = [
    { name: 'Cloud1', speed: 110 },
    { name: 'Cloud2', speed: 30 },
    { name: 'Cloud3', speed: 20 },
    { name: 'Cloud4', speed: 80 },
  ];

  const dots = withArtDirection(getImage(data.dots), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.dotsMobile),
    },
  ]);

  const maxHeight = height * 1.9;
  const dotsPosition = y <= maxHeight
    ? `calc(60vh + ${-((y * 110) / 100)}px)`
    : `calc(60vh + ${-((maxHeight * 110) / 100)}px)`;

  return (
    <>
      <style type="text/css">
        {`.position-dots { position: fixed !important; transform: translate3d(0px, ${dotsPosition}, 0px); }`}
        {layers.map(({ name, speed }) => {
          const position = y <= maxHeight
            ? `calc(60vh + ${-((y * speed) / 100)}px)`
            : `calc(60vh + ${-((maxHeight * speed) / 100)}px)`;

          return `.position-${name} { position: fixed !important; transform: translate3d(0px, ${position}, 0px); }`;
        })}
      </style>
      {layers.map(({ name }) => {
        const light = withArtDirection(getImage(data[`light${name}`]), [
          {
            media: '(max-width: 768px)',
            image: getImage(data[`light${name}Mobile`]),
          },
        ]);

        const dark = withArtDirection(getImage(data[`dark${name}`]), [
          {
            media: '(max-width: 768px)',
            image: getImage(data[`dark${name}Mobile`]),
          },
        ]);

        return (
          <Fragment key={name}>
            <GatsbyImage
              image={light}
              key={`light-${name}`}
              className={`clouds img-light position-${name}`}
              imgStyle={{ objectPosition: 'center top' }}
              alt=""
            />
            <GatsbyImage
              image={dark}
              key={`dark-${name}`}
              className={`clouds img-dark position-${name}`}
              imgStyle={{ objectPosition: 'center top' }}
              alt=""
            />
          </Fragment>
        );
      })}
      <GatsbyImage
        className="clouds position-dots"
        image={dots}
        imgStyle={{ objectPosition: 'center top' }}
        alt=""
      />
    </>
  );
};

export default Image;
